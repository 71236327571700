import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Moteur from "../components/moteur"

export default () => (
  <StaticQuery
    query={graphql`
      query AllHotels {
        allHotelsJson {
          group(field: ville) {
            nodes {
              id
              title
              fields {
                slug
              }
            }
            fieldValue
          }
        }
      }
    `}
    render={data => <IndexPage data={data} />}
  />
)

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <section className={"bg-white"}>
      <div className={"container"}>
        <Moteur />
      </div>
    </section>
    <div className={"container"} style={{ padding: "30px 0" }}>
      <ul className={"villes"}>
        {data.allHotelsJson.group.length > 0 &&
          data.allHotelsJson.group.map(group => (
            <Hotels key={group.fieldValue} data={group}></Hotels>
          ))}
      </ul>
    </div>
  </Layout>
)

const Hotels = ({ data }) => {
  let hotels = data.nodes.map(hotel => (
    <li key={hotel.fields.slug}>
      <Link to={`/${hotel.fields.slug}`}>{hotel.title}</Link>
    </li>
  ))

  return (
    <li>
      <h3>{data.fieldValue}</h3>
      <ul>{hotels}</ul>
    </li>
  )
}
